import React from 'react'
import { Text } from '@/components/common'
import { ICampaignTag } from 'src/types/generated/contentful'
import ABTester, { Experiment } from '@/components/ABTester'

interface Props {
  tags?: ICampaignTag[]
  className?: string
}

export const CampaignTags: React.FC<Props> = ({ tags = [], className }) => {
  if (!tags.length) return null

  return (
    <ul className={className}>
      {tags.map(({ fields, sys }) => (
        <li
          key={sys.id}
          className="inline-flex items-center bg-[#F5F4F3] rounded-sm p-[7px] text-gray-2 mr-2 last:mr-0"
        >
          <Text
            as="span"
            preset="custom"
            className="text-2xs uppercase font-bold leading-none"
          >
            {fields.title}
          </Text>
        </li>
      ))}
    </ul>
  )
}

type ExperimentType = 'on' | 'off' | 'tags_only' | 'description_only'

const ABCampaignTags: React.FC<Props> = (props) => {
  // Don't expose experiment if no tags
  if (!props.tags) return <CampaignTags {...props} />

  return (
    <ABTester name="campaign_tags">
      {({ decision }: Experiment<ExperimentType>) => {
        const tags =
          decision.variationKey === 'on' ||
          decision.variationKey === 'tags_only'
            ? props.tags
            : undefined
        return <CampaignTags {...props} tags={tags} />
      }}
    </ABTester>
  )
}

export default ABCampaignTags
