import { useMemo, useEffect, useState } from 'react'
import useCampaign from './useCampaign'
import { OptimizelyWindow } from '@/components/OptimizelyProvider/OptimizelyProvider'
import { getTimeRemaining } from '@/services/api/campaignV2/resolver'

const useCampaignAB = (slug: string) => {
  const [variationKey, setVariationKey] = useState('off')
  const { campaign, ...rest } = useCampaign(slug)

  const hoursRemaining = useMemo(() => {
    if (!campaign) return null

    const tr = getTimeRemaining(campaign, 72)

    return tr.timeRemainingDimension.toLowerCase().includes('day')
      ? tr.timeRemainingValue * 24
      : tr.timeRemainingValue
  }, [campaign])

  useEffect(() => {
    if (hoursRemaining && hoursRemaining <= 72 && hoursRemaining >= 36) {
      const decision = (
        window as OptimizelyWindow
      ).optimizelyClientInstance?.decide('campaign_remaining_hours')
      setVariationKey(decision?.variationKey || 'off')
    }
  }, [hoursRemaining])

  const resolvedCampaign = useMemo(() => {
    if (variationKey === 'off' || !hoursRemaining || hoursRemaining > 72) {
      return campaign
    }

    return {
      ...campaign,
      timeRemaining: {
        timeRemainingValue: hoursRemaining,
        timeRemainingDimension: hoursRemaining === 1 ? 'Hour' : 'Hours',
      },
    }
  }, [campaign, hoursRemaining, variationKey])

  return {
    campaign: resolvedCampaign,
    ...rest,
  }
}

export default useCampaignAB
